<template>
    <section id="fundraisng">
        <div class="container">
            <div class="pb-5">
                <h2><small>LAUNCHPAD</small> <br> Built for up-and-coming NFT artists and creators, thousands of NFT enthusiasts will launch their projects with everything necessary provided.</h2>
            </div>

            <div class="categories pt-3">
                <a href="">All Categories</a>
                <a href="" class="active">Art</a>
                <a href="">Metaverse</a>
                <a href="">Collectibles</a>
                <a href="">GameFi</a>
                <a href="">Play to Earn</a>
                <a href="">Cards</a>
                <a href="">Utility</a>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        data () {
            return {}
        }
    }
</script>