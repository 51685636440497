<template>
    <section id="bannerTitle">
        <div class="container">
            <h1>An NFT launchpad from the Fantom community. Launch your projects on the fastest blockchain.</h1>
            <h4><br/>As a creator, you need a community to launch your projects, showcase talent, and promote your NFTs. Well, help is here. Welcome to Fantoon!</h4>
        </div>
    </section>
</template>
<script>
    export default {
        data () {
            return {}
        }
    }
</script>
