<template>
    <section id="demo">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 mt-4">
                    <aside>
                        <span class="demo1"></span>
                        <div class="inner p-3">
                            <div href="" class="d-flex justify-content-between">
                                <img src="../../assets/images/img.png" alt="">
                                <a href="">Ended Successfully</a>
                            </div>

                            <div class="pt-3">
                                <h3>Pumpkitten <small>GameFi</small></h3>
                                <h4>Total NFTs <samp>100</samp>
                                </h4>
                                <h4>Mint Price <samp>$200 FTM</samp>
                                </h4>
                                <h4>Launch Date <samp>16th September 2021</samp>
                                </h4>
                            </div>
                        </div>
                    </aside>
                </div>

                <div class="col-12 col-md-6 col-lg-4 mt-4">
                    <aside>
                        <span class="demo5"></span>
                        <div class="inner p-3">
                            <div href="" class="d-flex justify-content-between">
                                <img src="../../assets/images/img.png" alt="">
                                <a href="">Ended Successfully</a>
                            </div>

                            <div class="pt-3">
                                <h3>BitKittens <small>GameFi</small></h3>
                                <h4>Total NFTs <samp>300</samp>
                                </h4>
                                <h4>Mint Price <samp>$300 FTM</samp>
                                </h4>
                                <h4>Launch Date <samp>15th October 2021</samp>
                                </h4>
                            </div>
                        </div>
                    </aside>
                </div>

                <div class="col-12 col-md-6 col-lg-4 mt-4">
                    <aside>
                        <span class="demo6"></span>
                        <div class="inner p-3">
                            <div href="" class="d-flex justify-content-between">
                                <img src="../../assets/images/img.png" alt="">
                                <a href="">Minting is LIVE</a>
                            </div>

                            <div class="pt-3">
                                <h3>SamuraiKittens <small>GameFi</small></h3>
                                <h4>Total NFTs <samp>300</samp>
                                </h4>
                                <h4>Mint Price <samp>$450 FTM</samp>
                                </h4>
                                <h4>Launch Date <samp>16th January 2022</samp>
                                </h4>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        data () {
            return {}
        }
    }
</script>