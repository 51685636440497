<template>
    <section id="comingSoon">
        <div class="container">
            <div class="row align-items-center">
                <aside class="col-12 col-xl-6">
                    <div class="inner">
                        <div class="d-flex align-items-center mr-3">
                            <img src="../../assets/images/icon.png" alt="">
                            <h3>
                                MexiTails <small>ART</small><br>
                                <strong>300</strong> <small>Mexican NFTs</small>
                            </h3>

                        </div>
                        <div class="my-5 mr-md-5 mr-0 pr-md-4 pr-0">
                            <h4>Date of launch <samp>14th April 2022</samp>
                            </h4>
                            <h4>Mint Price <samp>250 FTM</samp>
                            </h4>
                            <h4>Artist <samp>Philippe Crypto</samp>
                            </h4>
                            <h4>Minting Token <samp>FTM</samp>
                            </h4>
                            <h4>Mint Limit <samp>Max 5 per wallet</samp>
                            </h4>
                        </div>

                        <div class="mt-2">
                            <h4>Mint is now live</h4>
                            <div class="timer">
                                <span>0 <br> <small>Days</small></span><samp>:</samp>
                                <span>0 <br> <small>Hours</small></span><samp>:</samp>
                                <span>0 <br> <small>Minutes</small></span><samp>:</samp>
                                <span>0 <br> <small>Seconds</small></span>
                            </div>
                        </div>
                    </div>

                </aside>

                <article class="col-12 col-xl-6 mt-5 mt-xl-0">
                    <h2>MexiTail NFTs are coming first ;)</h2>
                    <p class="text-justify">AN AMAZING CULTURAL COLLECTION COMING TO YOU FROM MEXICO! A BRILLIANT SET OF 300 MEXITAILS. EVERY NFT INCLUDES A PHYSICAL ART PIECE MADE BY MEXICAN ARTISANS WITH THE NAME OF YOUR MEXITAIL ON THE BACK.</p>
                    <div class="d-flex flex-column flex-md-row">
                        <a href="https://fantoon.com/mexitails" class="active">Mint now</a>
                        <a href="https://docs.pumpkitten.com/fantoon/mexitails-the-first-project"><i class="paper"></i> Read Docs</a>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        data () {
            return {}
        }
    }
</script>
