<template>
    <section id="support">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-8 logoSec">
                    <h6>An initiative supported by</h6>
                    <div class="mt-4">
                        <img src="../../assets/images/external-content.duckduckgo.com.png" style="max-width: 70px;" title="BloqBall">

                        <img src="../../assets/images/external-content.duckduckgocom.png" style="max-width: 70px;" title="Millenium Club">

                        <img src="../../assets/images/external-contt.duckduckgo.com.png" style="max-width: 70px;" title="Spirit Swap">

                        <img src="../../assets/images/Logo Oracle.png" style="max-width: 70px;" title="Pumpkitten">

                    </div>
                </div>
                <div class="col-12 col-md-4 mt-5 mt-md-0">
                    <h5>Scroll down to see ongoing projects <br> and give them your support!</h5>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        data () {
            return {}
        }
    }
</script>
